import firebaseConfig from './config';
import _ from 'lodash';
import { initializeApp } from 'firebase/app';
// import 'firebase/storage';
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from 'firebase/auth';
import {
  getFirestore,
  onSnapshot,
  writeBatch,
  increment,
  collection,
  query,
  where,
  limit,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { navigate } from 'gatsby';

const app = initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = getAuth(app);
      this.db = getFirestore(app);
      this.functions = getFunctions(app);
      this.storage = getStorage(app);
    }
  }

  getUserProfile({ userId, handler }) {
    // onSnapshot(
    //   query(
    //     collection(this.db, 'Registrations'),
    //     where('userId', '==', userId),
    //     limit(1)
    //   ),
    //   (docs) => handler(docs)
    // );

    onSnapshot(doc(this.db, `Registrations/${userId}`), (doc) =>
      handler(doc.data())
    );
  }

  async changePassword({ email, currentPassword, newPassword }) {
    const user = this.auth.currentUser;
    const credential = EmailAuthProvider.credential(email, currentPassword);
    //   .then((data) => {
    //     console.log('dd', data);
    //   })
    //   .catch((error) => {
    //     console.log('fg', error);
    //   });
    // const credential = Promise.resolve(
    //   EmailAuthProvider.credential(email, currentPassword)
    // );

    // credential
    //   .then((data) => {
    //     console.log('dd', data);
    //   })
    //   .catch((error) => {
    //     console.log('fg', error);
    //   });
    return reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            return 'success';
          })
          .catch((error) => {
            console.log('fg', error);
            return error;
          });
      })
      .catch((err) => {
        console.log('fg2', err.code);
        return err;
      });
  }

  async user({ uid }) {
    const docRef = doc(this.db, `Registrations/${uid}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async fetchForm({ id }) {
    const docRef = doc(this.db, `Registrations/${id}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async juju() {
    // const courseForm = {
    //   sittingsNo: '1',
    //   maidenName: '',
    //   curricular: 'DANCE',
    //   gender: 'Female',
    //   nokAddress: '122, IJESHA ROAD, ITIRE, LAGOS',
    //   denomination: 'PENTECOSTAL',
    //   firstName: 'PRUDENCE',
    //   documents: [
    //     {
    //       name: 'IBE BIRTH.jpg',
    //       url:
    //         'https://firebasestorage.googleapis.com/v0/b/luth-48cc9.appspot.com/o/registrations%2FebKcakLjX9gEi9aGOaLeq1c1BNc2%2Fdocs%2FIBE%20BIRTH.jpg?alt=media&token=678f7a57-5eb2-4b35-a2f6-3fcf4ce098a6',
    //     },
    //     {
    //       url:
    //         'https://firebasestorage.googleapis.com/v0/b/luth-48cc9.appspot.com/o/registrations%2FebKcakLjX9gEi9aGOaLeq1c1BNc2%2Fdocs%2FIBE%20JAMB.jpg?alt=media&token=cd8ab07e-1f52-4d98-a9cf-d8ac1fb6a332',
    //       name: 'IBE JAMB.jpg',
    //     },
    //     {
    //       url:
    //         'https://firebasestorage.googleapis.com/v0/b/luth-48cc9.appspot.com/o/registrations%2FebKcakLjX9gEi9aGOaLeq1c1BNc2%2Fdocs%2FIBE%20JAMB.jpg?alt=media&token=cd8ab07e-1f52-4d98-a9cf-d8ac1fb6a332',
    //       name: 'IBE JAMB.jpg',
    //     },
    //   ],
    //   DateSubmitted: 1657720230655,
    //   phone: '09129058802',
    //   localGov: 'OWERRI NORTH',
    //   nokPhone: '08176070916',
    //   appNo: '2022/0457',
    //   course: 'General Nursing',
    //   kin: 'MRS IBE CHIOMA',
    //   maritalStatus: 'Single',
    //   email: 'ibechioma92@gmail.com',
    //   subjectsNo: 'FIVE',
    //   subjects: [
    //     {
    //       grade2: 'A1',
    //       grade1: 'C4',
    //       grade4: 'C5',
    //       sub4: 'PHY',
    //       grade5: 'B2',
    //       details: 'WAEC/2021/4254331064',
    //       sub3: 'BIO',
    //       grade3: 'B2',
    //       sub5: 'CHEM',
    //       sub1: 'ENG',
    //       sub2: 'MATHS',
    //     },
    //   ],
    //   lastName: 'IBE',
    //   address: '11, AYOELEGBEDE STREET, AGUDA, SURULERE, LAGOS',
    //   education: [
    //     {
    //       others: 'ENITAN NURSERY AND PRIMARY SCHOOL, AGUDA SURULERE, LAGOS',
    //       to: '2017',
    //       from: '2015',
    //       type: '',
    //     },
    //     {
    //       from: '2017',
    //       to: '2021',
    //       others: 'STANDARD GEK COLLEGE, EJIGBO, LAGOS',
    //       type: 'Secondary',
    //     },
    //   ],
    //   dob: '2006-05-28',
    //   middleName: 'CHINAZA',
    //   flag: 'Submitted',
    //   religion: 'CHRISTIANITY',
    //   state: 'IMO',
    //   passport:
    //     'https://firebasestorage.googleapis.com/v0/b/luth-48cc9.appspot.com/o/registrations%2FebKcakLjX9gEi9aGOaLeq1c1BNc2%2Fpic%2FIBE%20PASSPORT.jpg?alt=media&token=9a9cc89a-2b15-4090-82a1-ad39802783d3',
    //   nationality: 'NIGERIAN',
    //   uid: 'ebKcakLjX9gEi9aGOaLeq1c1BNc2',
    // };
    const fReg = doc(this.db, 'Registrations/ebKcakLjX9gEi9aGOaLeq1c1BNc2');
    const docSnap = await getDoc(fReg);
    const { courseForm } = docSnap.data();
    console.log('course', courseForm);

    const sReg = doc(this.db, 'Registrations/4XdQ2W6zyrcqdCFzwkqqKPpwyxD2');
    return await updateDoc(sReg, { courseForm, submitted: true });
  }

  userApi({ uid }) {
    onSnapshot(
      doc(this.db, `Registrations/${uid}`),
      (doc) => doc.data()
      // console.log('Current data: ', doc.data());
    );

    //   const unsub = onSnapshot(doc(db, "cities", "SF"), (doc) => {
    //     console.log("Current data: ", doc.data());
    // });
  }

  async storeFiles({ path, docFile }) {
    const pathRef = ref(this.storage, path);
    return await uploadBytes(pathRef, docFile).then(async (snapshot) => {
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    });
  }

  

  async saveReport() {
    const savers = httpsCallable(this.functions, 'saveReport');
    return savers();
  }

  async register({ email, password, username }) {
    await createUserWithEmailAndPassword(this.auth, email, password);
    const createProfileCallable = httpsCallable(
      this.functions,
      'createPublicProfile'
    );
    return createProfileCallable({
      username,
    });
  }

  async login({ email, password }) {
    return await signInWithEmailAndPassword(this.auth, email, password);
  }

  async forgot({ email }) {
    return await sendPasswordResetEmail(this.auth, email);
  }

  async logout() {
    await signOut(this.auth);
  }

  async generateRRR(data) {
    const call = httpsCallable(this.functions, 'generateRRR');
    return call(data);
  }

  async preRegisteredUser({ email }) {
    const call = httpsCallable(this.functions, 'findAccount');
    return call({
      email,
    });
  }

  async registerAccount({ email, password }) {
    return await createUserWithEmailAndPassword(this.auth, email, password);
  }

  verifyTransport() {
    const call = httpsCallable(this.functions, 'verifyTransport');
    return call();
  }

  async fetchComp() {
    const q = query(
      collection(this.db, 'Registrations'),
      where('status', '==', 'Completed')
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, ' => ', doc.data());
    });
  }

  listall() {
    const call = httpsCallable(this.functions, 'listall');
    return call();
  }

  async storeRegistration({ dbData, callData }) {
    const regRef = doc(this.db, `Registrations/${dbData.userId}`);
    const codeRef = doc(this.db, `Codes/${dbData.rrr}`);
    await setDoc(regRef, dbData);
    await setDoc(codeRef, {
      id: dbData.userId,
      status: dbData.status,
    });

    const call = httpsCallable(this.functions, 'welcomeMail');
    return call(callData);
  }

  async applyCourse({ data, id }) {
    const batch = writeBatch(this.db);
    const regRef = doc(this.db, `Registrations/${id}`);
    let counterRef = doc(this.db, `meta/${data.course.replace(/ /g, '')}`);
    if (
      data.course === 'Perioperative Program' ||
      data.course === 'Accident & Emergency Program' ||
      data.course === 'Ophthalmic Program' ||
      data.course === 'Paediatric Program'
    ) {
      counterRef = doc(this.db, `meta/PBNursing`);
    }
    if (
      data.course === 'Anaesthetic Technicians' 
    ) {
      counterRef = doc(this.db, `meta/ AnaestheticTechnicians`);
    }
    if (
      data.course === 'School of Community Health' 
    ) {
      counterRef = doc(this.db, `meta/CommunityHealthOfficersProgram`);
    }
    if (
      data.course === 'Health Information Management (ND)' ||
      data.course === 'Health Information Management (HND)'
    ) {
      counterRef = doc(this.db, `meta/HIM`);
    }
    if (
      data.course === 'Biomedical Engineering (ND)' ||
      data.course === 'Biomedical Engineering (HND)'
    ) {
      counterRef = doc(this.db, `meta/BioMed`);
    }
    const docSnap = await getDoc(counterRef);
    if (docSnap.exists()) {
      //fetch counter
      const statsData = docSnap.data();

      //use counter value 2 increment app no
      const currentTime = new Date();
      const currentYear = currentTime.getFullYear().toString();     

      const statsCol = `${currentYear}count`;
      const currentCount = statsData[statsCol];
      const newCount = currentCount + 1;
      const paddedCount = _.padStart(newCount, 4, '0');
      let appNo = '';
      if (data.course === 'School of Community Health') {
        appNo = `CHOTS${currentYear.slice(2)}/${paddedCount}`;
      } else {
        appNo = `${currentYear}/${paddedCount}`;
      }
      data.appNo = appNo;

      //increment counter value
      batch.update(counterRef, {
        [`${statsCol}`]: increment(1),
      });
      batch.update(regRef, { courseForm: data, submitted: true });

      const call = httpsCallable(this.functions, 'saveReport');
      call(data);

      await batch.commit();
      return appNo;
    }
    return 'no doc';
  }

  async checkTransStatus({ code }) {
    const call = httpsCallable(this.functions, 'checkTransStatus');
    return call({ rrr: code });
  }

  async updateStatus({ uid }) {
    const regRef = doc(this.db, `Registrations/${uid}`);
    return await updateDoc(regRef, { status: 'Completed' });
  }

  computeSplit() {
    const data = {
      ID: 1308,
      Amount: 4500,
      Currency: 'NGN',
      CustomerEmail: 'anon8@customers.io',
      SplitInfo: [
        // {
        //   SplitType: 'FLAT',
        //   SplitValue: 450,
        //   SplitEntityId: 'LNPYACC0019',
        // },
        // {
        //   SplitType: 'FLAT',
        //   SplitValue: 2300,
        //   SplitEntityId: 'LNPYACC0011',
        // },
        {
          SplitType: 'FLAT',
          SplitValue: 450,
          SplitEntityId: 'LNPYACC0019',
        },
        {
          SplitType: 'RATIO',
          SplitValue: 3,
          SplitEntityId: 'LNPYACC0011',
        },
        {
          SplitType: 'PERCENTAGE',
          SplitValue: 3,
          SplitEntityId: 'LNPYACC0015',
        },
        {
          SplitType: 'RATIO',
          SplitValue: 2,
          SplitEntityId: 'LNPYACC0016',
        },
        {
          SplitType: 'FLAT',
          SplitValue: 2450,
          SplitEntityId: 'LNPYACC0029',
        },
        {
          SplitType: 'PERCENTAGE',
          SplitValue: 10,
          SplitEntityId: 'LNPYACC0215',
        },
      ],
    };

    const checkForGreaterSplit = data.SplitInfo.some(
      (val) => val.SplitValue > data.Amount
    );

    const checkForLessSplit = data.SplitInfo.some((val) => val.SplitValue < 0);

    const allSplit = data.SplitInfo.map((element) => element.SplitValue).reduce(
      (a, b) => a + b,
      0
    );

    const checkGreatestSplit = allSplit > data.Amount;

    const checkInfoLength =
      data.SplitInfo.length >= 1 && data.SplitInfo.length <= 20;

    if (
      !checkForGreaterSplit &&
      !checkForLessSplit &&
      !checkGreatestSplit &&
      checkInfoLength
    ) {
      let sortByType = data.SplitInfo.sort(function (a, b) {
        var nameA = a.SplitType.toLowerCase(),
          nameB = b.SplitType.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      // console.log('sortbyType', sortByType);

      const checkForRatio = sortByType.some(
        (type) => type.SplitType === 'RATIO'
      );
      let ratioSum = 0;

      // console.log('check for ratio', checkForRatio);

      let ratioArray = null;
      if (checkForRatio) {
        ratioArray = sortByType.filter(
          (element) => element.SplitType === 'RATIO'
        );
        ratioSum = ratioArray
          .map((element) => element.SplitValue)
          .reduce((a, b) => a + b, 0);
      }

      const newSplitInfo = [];
      let initialBalance = data.Amount;
      let newBalance = initialBalance;
      let openingBal = 0;

      sortByType.forEach((ele) => {
        if (
          ele.SplitType === 'FLAT' ||
          ele.SplitType === 'PERCENTAGE' ||
          ele.SplitType === 'RATIO'
        ) {
          if (ele.SplitType === 'FLAT') {
            // debugger;
            newBalance = newBalance - ele.SplitValue;
            console.log(
              `Split amount for ${ele.SplitEntityId}: `,
              ele.SplitValue
            );
            console.log(
              `Balance after split calculation for ${ele.SplitEntityId} ${newBalance} - ${ele.SplitValue}:: ${newBalance}`
            );
            openingBal = newBalance;
          }
          if (ele.SplitType === 'PERCENTAGE') {
            const pVal = (ele.SplitValue / 100) * newBalance;
            newBalance = newBalance - pVal;
            console.log(
              `Split amount for ${ele.SplitEntityId}: (${ele.SplitValue} % of ${newBalance}) = ${pVal}`
            );
            console.log(
              `Balance after split calculation for ${ele.SplitEntityId} ${newBalance} - ${pVal}:: ${newBalance}`
            );
            openingBal = newBalance;
          }
          if (ele.SplitType === 'RATIO') {
            const rVal = (ele.SplitValue / ratioSum) * openingBal;
            newBalance = newBalance - rVal;
            console.log(
              `Split amount for ${ele.SplitEntityId}: (${ele.SplitValue}/${ratioSum} * ${newBalance}) = ${rVal}`
            );
            console.log(
              `Balance after split calculation for ${ele.SplitEntityId}: ${newBalance} - ${rVal} :: ${newBalance}`
            );
          }
          const obj1 = {
            SplitEntityId: ele.SplitEntityId,
            Amount: ele.SplitValue,
          };
          newSplitInfo.push(obj1);
        } else {
          console.log('error dey here too');
        }
      });

      const responseObj = {
        ID: data.ID,
        Balance: newBalance,
        SplitBreakdown: newSplitInfo,
      };

      console.log('final bal', newBalance);
      console.log('response', responseObj);

      if (newBalance < 0) {
        console.log('error');
      }
    } else {
      console.log('wrong input');
    }
  }

  async fetchRRR({ id }) {
    const regRef = doc(this.db, `Registrations/${id}`);
    const docSnap = await getDoc(regRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.status === 'Not Completed') {
        const codeRef = doc(this.db, `Codes/${data.rrr}`);
        const codeSnap = await getDoc(codeRef);
        if (codeSnap.exists()) {
          const codeData = codeSnap.data();
          const call = httpsCallable(this.functions, 'checkTransStatus');
          return call({ rrr: codeSnap.id });
        } else {
          console.log('e no exist');
        }
      } else if (data.status === 'Completed') {
        return navigate('/login');
      }
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  }
}

let firebaseInstance;

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase();
    return firebaseInstance;
  } else if (firebaseInstance) {
    return firebaseInstance;
  } else {
    return null;
  }
}

export default getFirebaseInstance;
