import React from 'react';
import { Link } from 'gatsby';
import footer1 from '../assets/images/footer/1.jpg';
import footer2 from '../assets/images/footer/5.jpg';
import footer3 from '../assets/images/footer/11.jpg';
import footer4 from '../assets/images/footer/2.jpg';
import footer5 from '../assets/images/footer/12.jpg';
import footer6 from '../assets/images/footer/13.jpg';

const Footer = () => {
  function scrollTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <footer className='site-footer'>
        <div className='site-footer__upper'>
          <div className='container'>
            <div className='row'>
              {/* <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__contact">
                  <h2 className="footer-widget__title">Courses</h2>
                  <ul className="list-unstyled footer-widget__course-list">
                    <li>
                      <h2>
                        <a href="course-details.html">
                          Introduction Web Design
                        </a>
                      </h2>
                      <p>Mike Hardson</p>
                    </li>
                    <li>
                      <h2>
                        <a href="course-details.html">
                          {" "}
                          Learning MBA Management{" "}
                        </a>
                      </h2>
                      <p>Jessica Brown</p>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className='col-xl-3 col-lg-6 col-sm-12'>
                <div className='footer-widget footer-widget__link'>
                  <h2 className='footer-widget__title'>Explore</h2>
                  <div className='footer-widget__link-wrap'>
                    <ul className='list-unstyled footer-widget__link-list'>
                      <li>
                        <Link to='/about-us'>About</Link>
                      </li>
                      <li>
                        <Link to='/schools'>Schools</Link>
                      </li>
                      <li>
                        <Link to='/departments'>Departments</Link>
                      </li>
                      <li>
                        <Link to='/events'>Events</Link>
                      </li>
                      <li>
                        <Link to='/contact'>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-6 col-sm-12 offset-lg-3'>
                <div className='footer-widget footer-widget__gallery'>
                  <h2 className='footer-widget__title'>Gallery</h2>
                  <ul className='list-unstyled footer-widget__gallery-list'>
                    <li>
                      <Link to='/events'>
                        <img src={footer1} alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/events'>
                        <img src={footer2} alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/events'>
                        <img src={footer4} alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/events'>
                        <img src={footer5} alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/events'>
                        <img src={footer6} alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/events'>
                        <img src={footer3} alt='' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xl-3 col-lg-6 col-sm-12'>
                <div className='footer-widget footer-widget__about'>
                  <h2 className='footer-widget__title'>About</h2>
                  <p className='footer-widget__text'>
                    The Lagos University Teaching Hospital today is a foremost
                    tertiary hospital with a mandate to provide excellent
                    services of international standard in patient care, training
                    & research
                  </p>
                  {/* <div className='footer-widget__btn-block'>
                    <a href='#none' className='thm-btn'>
                      Contact
                    </a>
                    <a href='#none' className='thm-btn'>
                      Purchase
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='site-footer__bottom'>
          <div className='container'>
            <p className='site-footer__copy'>
              &copy; Copyright {new Date().getFullYear()}{' '}
              <a href='#none'>Lagos University Teaching Hospital </a>
            </p>
            <div className='site-footer__social'>
              <div
                onClick={scrollTop}
                onKeyDown={scrollTop}
                role='button'
                tabIndex={0}
                className='scroll-to-target site-footer__scroll-top'
              >
                <i className='kipso-icon-top-arrow'></i>
              </div>

              <a href='#none'>
                <i className='fab fa-facebook-square'></i>
              </a>
              <a href='https://twitter.com/luthofficialinf'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='https://www.youtube.com/channel/UCsSW_ST7Iw3xDzU41SYHKkg' target='_blank'>
                <i className='fab fa-youtube'></i>
              </a>
              <a href='https://www.instagram.com/luthofficialinfo/?hl=en%5C'>
                <i className='fab fa-instagram'></i>
              </a>
            </div>
          </div>
        </div>
      </footer>

      <div className='search-popup'>
        <div className='search-popup__overlay custom-cursor__overlay search-overlay'></div>
        <div className='search-popup__inner'>
          <form action='#' className='search-popup__form'>
            <input
              type='text'
              name='search'
              placeholder='Type here to Search....'
            />
            <button type='submit'>
              <i className='kipso-icon-magnifying-glass'></i>
            </button>
            <div className='cancel'></div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Footer;
