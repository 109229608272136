import firebaseInstance from "./firebase"; // Import firestore and storage
import useAuth from './useAuth';
import { firestore, storage } from "./firebasemanuscript";
import FirebaseContext from "./context";

// export { FirebaseContext, useAuth };
// export default getFirebaseInstance;


export { FirebaseContext, useAuth, firestore, storage };
export default firebaseInstance;
